import Typography from "@mui/material/Typography";
import MenuItem from "@mui/material/MenuItem";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import { sortBy } from "lodash";
import { ProfessionContext } from "./ProfessionContext";
import { useContext, useEffect } from "react";

interface ProfessionSelectPropI {
  id: string;
  value: number;
  setValue: (value: number) => void;
  label?: string;
  labelId?: string;
  active?: boolean;
  filterIds?: number[];
  labelNoProfession?: string;
  filterByType?: string;
  filterByNoParent?: boolean;
  disabledExpired?: boolean;
}

export const ProfessionSelect = (props: ProfessionSelectPropI) => {
  const { professionList } = useContext(ProfessionContext);
  const filterIds = props.filterIds ? props.filterIds : [];
  const filtereredProfessionList = professionList
    .filter(
      (p) => (!props.active || p.active) && !filterIds.some((e) => e === p.id)
    )
    .filter((p) => {
      return !props.filterByType || p.type === props.filterByType;
    })
    .filter((p) => {
      return (
        !props.filterByNoParent ||
        !professionList.some((e) => e.parentId === p.id)
      );
    });

  useEffect(() => {
    console.log(
      "%csrc/components/profession/ProfessionSelect.tsx:38 filtereredProfessionList",
      "color: #007acc;",
      filtereredProfessionList
    );
  });

  console.log(
    "%csrc/components/profession/ProfessionSelect.tsx:38 filtereredProfessionList",
    "color: #007acc;",
    filtereredProfessionList
  );

  return (
    <Select
      size="medium"
      labelId={props.labelId}
      id={props.id}
      value={
        props.value &&
        filtereredProfessionList.some((e) => e.id === props.value)
          ? props.value
          : 0
      }
      onChange={(e: SelectChangeEvent<number>) => {
        props.setValue(e.target.value as number);
      }}
      label={props.label || "Version von"}
      sx={{ width: "100%", minWidth: 150 }}
    >
      <MenuItem value={0}>
        <Typography variant="body2" data-testid="profession-select-empty">
          {props.labelNoProfession ? props.labelNoProfession : "---"}
        </Typography>
      </MenuItem>
      {sortBy(filtereredProfessionList, [(e) => e.name.toLowerCase()]).map(
        (e) => (
          <MenuItem
            key={e.id}
            value={e.id}
            {...((e.numberTransactions &&
              e.numberCandidates !== undefined &&
              e.numberTransactions - e.numberCandidates <= 0) ||
            (e.expiredAt && e.expiredAt < new Date() && props.disabledExpired)
              ? { disabled: true }
              : {})}
          >
            <Typography variant="body2">
              {e.name} {e.testId}
              {e.numberTransactions && e.numberCandidates !== undefined && (
                <>
                  {" "}
                  ({e.numberCandidates}/{e.numberTransactions} verwendet)
                </>
              )}{" "}
              {e.expiredAt &&
                new Date(e.expiredAt).getTime() + 86400000 < // add one day
                  new Date().getTime() && <>&ndash; abgelaufen</>}
            </Typography>
          </MenuItem>
        )
      )}
    </Select>
  );
};
